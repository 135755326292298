.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.preview-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
    /* distribute evently */
    justify-content: space-evenly;
    height: 10vh;
}

 .preview-video {
    height: 100%;
} 

.preview-video:hover {
    transform: scale(1.1);
    border-color: black;
    border-width: 2px;
}
.skel-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.skel-canvas{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    /* aspect-ratio:  720 / 1280; */
    display: block; /* Remove inline-block whitespace */
}